var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"table-container",class:{ 'table-container-odd': !_vm.isPairNumberTableHeader }},[_c('div',{staticClass:"table-cell d-flex"},[_c('div',{staticClass:"primary-cell"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "evaluations2.test", false, "Instrumento" ).toUpperCase())+" ")]),_c('div',{staticClass:"secundary-cell title-instrument"},[(_vm.test)?_c('span',[_vm._v(" "+_vm._s(_vm.test.title)+" ")]):_vm._e()])]),(
        !(
          (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
          _vm.$debug_change_duoc
        )
      )?_c('div',{staticClass:"table-cell d-flex"},[_c('div',{staticClass:"primary-cell"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.test.agente_evaluativo", false, "Tipo" ).toUpperCase())+" ")]),_c('div',{staticClass:"secundary-cell"},[(_vm.typeDefault)?_c('span',[_vm._v(_vm._s(_vm.typeDefault.name))]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"table-cell d-flex"},[_c('div',{staticClass:"primary-cell"},[_vm._v("PONDERACIÓN")]),_c('div',{staticClass:"secundary-cell"},[(_vm.test)?_c('span',[_vm._v(" "+_vm._s(_vm.test.weighing)+"% ")]):_vm._e()])]),_c('div',{staticClass:"table-cell d-flex"},[_c('div',{staticClass:"primary-cell"},[_vm._v("PUNTAJE")]),_c('div',{staticClass:"secundary-cell"},[(_vm.test)?_c('span',[_vm._v(" "+_vm._s(_vm.test.max_score)+" ")]):_vm._e()])]),(
        _vm.evaluateeTest &&
        _vm.evaluatee_view &&
        _vm.finished_test &&
        _vm.evaluateeTest.is_published
      )?_c('div',{staticClass:"table-cell d-flex"},[_c('div',{staticClass:"primary-cell"},[_vm._v("PUNTAJE DEL OBTENIDO")]),_c('div',{staticClass:"secundary-cell"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("Round")(_vm.evaluateeTest.final_score != null ? _vm.evaluateeTest.final_score : _vm.evaluateeTest.manual_score != null ? _vm.evaluateeTest.manual_score : _vm.evaluateeTest.automated_score != null ? _vm.evaluateeTest.automated_score : _vm.institution.min_grade))+" ")])])]):_vm._e(),(!_vm.evaluatee_view && !_vm.evaluator_view)?_c('div',{staticClass:"table-cell d-flex"},[_c('div',{staticClass:"primary-cell"},[_vm._v("CREADO POR")]),_c('div',{staticClass:"secundary-cell"},[(_vm.test)?_c('span',[_vm._v(" "+_vm._s(_vm.getUser(_vm.test.created_by))+" ")]):_vm._e()])]):_vm._e(),(!_vm.evaluatee_view && !_vm.evaluator_view)?_c('div',{staticClass:"table-cell d-flex"},[_c('div',{staticClass:"primary-cell"},[_vm._v("FECHA DE CREACIÓN")]),_c('div',{staticClass:"secundary-cell"},[(_vm.test)?_c('span',[_vm._v(" "+_vm._s(_vm._f("FormatToDateTime")(_vm.test.creation_date))+" ")]):_vm._e()])]):_vm._e()]),(_vm.evaluateeTest && _vm.evaluateeTest.feedback)?_c('div',{staticClass:"table-cell-description"},[_c('div',{staticClass:"primary-cell-description"},[_vm._v("RETROALIMENTACIÓN")]),_c('div',{staticClass:"secundary-cell-description"},[_c('div',{staticClass:"rich-text-content",domProps:{"innerHTML":_vm._s(_vm.evaluateeTest.feedback)}})])]):_vm._e(),(_vm.test && _vm.institution && _vm.institution.internal_use_id != 'duoc_uc')?_c('div',{staticClass:"table-cell-description",class:{ 'print-rubric': _vm.isPrintingRubrica }},[_c('div',{staticClass:"primary-cell-description"},[_vm._v("DESCRIPCIÓN")]),_c('div',{staticClass:"secundary-cell-description"},[_c('div',{staticClass:"rich-text-content",domProps:{"innerHTML":_vm._s(_vm.test.description)}})])]):_vm._e(),(
      ((_vm.test && _vm.test.files_for_professor.length > 0) || _vm.allows_crud) &&
      _vm.user &&
      !_vm.preview_evaluation &&
      !_vm.user.groups.includes(7)
    )?_c('div',{staticClass:"table-cell-description",class:{
      'print-hide': _vm.isPrintingRubrica || _vm.isPrintingInstrumento,
    }},[_c('div',{staticClass:"primary-cell-description"},[_vm._v(" ANEXOS PARA EL DOCENTE "),(_vm.allows_crud)?_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(`Agregar Archivo`),expression:"`Agregar Archivo`",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"add-button",attrs:{"title":`Agregar Archivo`,"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
            `modal-select-files_for_professor-${_vm.instrument_id}-${_vm.container_instrument_id}`
          )}}}):_vm._e()],1),_c('div',{staticClass:"secundary-cell-description table-left"},[_c('div',[_c('FileContainer',{attrs:{"Files":_vm.test.files_for_professor,"allows_crud":_vm.allows_crud},on:{"deleteFile":_vm.slotDeleteFileProfessor}})],1)])]):_vm._e(),((_vm.test && _vm.test.files.length > 0) || _vm.allows_crud)?_c('div',{staticClass:"table-cell-description",class:{
      'print-hide': _vm.isPrintingRubrica || _vm.isPrintingInstrumento,
    }},[_c('div',{staticClass:"primary-cell-description"},[_vm._v(" ANEXOS PARA EL ESTUDIANTE "),(_vm.allows_crud)?_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(`Agregar Archivo`),expression:"`Agregar Archivo`",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"add-button",attrs:{"title":`Agregar Archivo`,"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
            `modal-select-file-${_vm.instrument_id}-${_vm.container_instrument_id}`
          )}}}):_vm._e()],1),_c('div',{staticClass:"secundary-cell-description table-left"},[_c('div',[_c('FileContainer',{attrs:{"Files":_vm.test.files,"allows_crud":_vm.allows_crud},on:{"deleteFile":_vm.slotDeleteFile}})],1)])]):_vm._e(),_c('b-modal',{attrs:{"id":`modal-select-file-${_vm.instrument_id}-${_vm.container_instrument_id}`,"title":"Seleccionar Archivos","hide-footer":""}},[_c('FileSelector',{on:{"created":_vm.slotCreatedFile}})],1),_c('b-modal',{attrs:{"id":`modal-select-files_for_professor-${_vm.instrument_id}-${_vm.container_instrument_id}`,"title":"Seleccionar Archivos","hide-footer":""}},[_c('FileSelector',{on:{"created":_vm.slotCreatedFileProfessor}})],1),(!_vm.evaluatee_view && !_vm.evaluator_view)?_c('div',{staticClass:"fixed-row",class:{ visible: _vm.showFixedRow }},[_c('div',{staticClass:"column column1"},[_vm._v(" Puntaje del "+_vm._s(_vm.$getVisibleNames("evaluations2.test", false, "Instrumento"))+" ")]),_c('div',{staticClass:"column column2"},[_vm._v(_vm._s(_vm.test.max_score))]),(_vm.questions.length > 0)?_c('div',{staticClass:"column column1"},[_vm._v(" Puntaje actual de las preguntas ")]):_vm._e(),(_vm.questions.length > 0)?_c('div',{staticClass:"column column2",class:{
        'score-input-warning': _vm.questionsMaxScore > _vm.test.max_score,
        'score-input-warning-minor': _vm.questionsMaxScore < _vm.test.max_score,
      }},[_c('div',{staticClass:"subcolumn"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom.noninteractive.v-danger",value:(
            `${
              _vm.questionsMaxScore > _vm.test.max_score
                ? `El puntaje de las preguntas es mayor que el puntaje de la ${_vm.$getVisibleNames(
                    'evaluations2.test',
                    true,
                    'Instrumento'
                  )}`
                : _vm.questionsMaxScore < _vm.test.max_score
                ? `El puntaje de las preguntas es menor que el puntaje de la ${_vm.$getVisibleNames(
                    'evaluations2.test',
                    true,
                    'Instrumento'
                  )}`
                : ''
            }`
          ),expression:"\n            `${\n              questionsMaxScore > test.max_score\n                ? `El puntaje de las preguntas es mayor que el puntaje de la ${$getVisibleNames(\n                    'evaluations2.test',\n                    true,\n                    'Instrumento'\n                  )}`\n                : questionsMaxScore < test.max_score\n                ? `El puntaje de las preguntas es menor que el puntaje de la ${$getVisibleNames(\n                    'evaluations2.test',\n                    true,\n                    'Instrumento'\n                  )}`\n                : ''\n            }`\n          ",modifiers:{"bottom":true,"noninteractive":true,"v-danger":true}}],staticClass:"subcolumn-item"},[_vm._v(" "+_vm._s(_vm.questionsMaxScore)+" "),(
              _vm.questionsMaxScore < _vm.test.max_score ||
              _vm.questionsMaxScore > _vm.test.max_score
            )?_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"exclamation-circle"}}):_vm._e()],1),_c('div',[(
              _vm.allows_crud &&
              _vm.institution &&
              _vm.institution.internal_use_id != 'duoc_uc'
            )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom.noninteractive.v-danger",value:(
              `${
                _vm.questionsMaxScore > _vm.test.max_score ||
                _vm.questionsMaxScore < _vm.test.max_score
                  ? `Igualar Puntaje total con respecto al Puntaje actual de las preguntas`
                  : ''
              }`
            ),expression:"\n              `${\n                questionsMaxScore > test.max_score ||\n                questionsMaxScore < test.max_score\n                  ? `Igualar Puntaje total con respecto al Puntaje actual de las preguntas`\n                  : ''\n              }`\n            ",modifiers:{"bottom":true,"noninteractive":true,"v-danger":true}}],staticClass:"icon-container",class:{
              'icon-visibility':
                _vm.questionsMaxScore > _vm.test.max_score ||
                _vm.questionsMaxScore < _vm.test.max_score,
            },attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.upgradeScoreInstrument}},[_c('b-icon',{attrs:{"icon":"clipboard-check"}})],1):_vm._e()],1)])]):_vm._e(),_c('div',{staticClass:"column column2 columnView"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.bottom.noninteractive.hover",value:(
          `${
            !_vm.preview_evaluation
              ? 'Revisar punto de vista del Estudiante'
              : 'Volver al punto de vista del Profesor'
          }`
        ),expression:"\n          `${\n            !preview_evaluation\n              ? 'Revisar punto de vista del Estudiante'\n              : 'Volver al punto de vista del Profesor'\n          }`\n        ",modifiers:{"v-secondary":true,"bottom":true,"noninteractive":true,"hover":true}}],staticClass:"btn-preview-web",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.ChangePreviewEvaluation}},[_vm._v(_vm._s(!_vm.preview_evaluation ? "Ver como Estudiante" : "Ver como Profesor")+" ")]),_c('b-button',{staticClass:"btn-preview-mobile",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.ChangePreviewEvaluation}},[(!_vm.preview_evaluation)?_c('b-icon',{staticClass:"icon-preview-on",attrs:{"icon":"eye"}}):_c('b-icon',{staticClass:"icon-preview-off",attrs:{"icon":"eye-slash"}})],1)],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }